'use client';

import { ErrorServiceUnavailableUI } from '../ErrorServiceUnavailableUI';
import { Error500Properties } from '../types';

import ErrorBoundary from '@/src/components/ErrorBoundary';
import type { ContentStackEntryDataProps } from '@/types/contentStack';

export const ErrorBoundaryClientWrapper = ({
    children,
    moduleSettingsList,
}: {
    children: React.ReactNode;
    moduleSettingsList: ContentStackEntryDataProps<Error500Properties>[];
}) => {
    const renderFallback = (error: Error, errorInfo?: string | null) => (
        <ErrorServiceUnavailableUI
            error={error}
            errorInfo={errorInfo}
            moduleSettingsList={moduleSettingsList}
        />
    );

    return (
        <>
            <ErrorBoundary fallback={renderFallback}>{children}</ErrorBoundary>
        </>
    );
};
