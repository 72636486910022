'use client';

import { useCurrentLocale } from 'next-i18n-router/client';
import Image from 'next/image';

import SearchPageDigitalDataLayer from '@/src/components/Analytics/SearchPageDigitalDataLayer';
import {
    ERROR_PAGE_ANALYTICS_PAGE_NAME,
    ERROR_PAGE_DESCRIPTION_KEY,
    ERROR_PAGE_IMAGES_DEVELOPMENT_KEY,
    ERROR_PAGE_IMAGE_KEY,
    ERROR_PAGE_LIST_HEADING_KEY,
    ERROR_PAGE_TITLE_KEY,
} from '@/src/components/ErrorServiceUnavailable/constants';
import { i18nConfig } from '@/src/constants/language';
import {
    getImageUrl,
    getImagesData,
    getKeyValue,
} from '@/src/services/content/business.lib';
import { LocaleType } from '@/src/types/pages/search';
import { Link, List, ListItem, Text } from '@costcolabs/forge-components';
import {
    ColorBlue600,
    FontSize200,
    FontSize300,
    FontSize400,
    FontSize500,
    FontSize600,
    SpaceLg,
    SpaceMd,
    SpaceXl,
    SpaceXs,
    SpaceXxl,
} from '@costcolabs/forge-design-tokens';
import { Box, Container } from '@mui/material';

import { ErrorServiceUnavailableUIProps } from './types';

export const ErrorServiceUnavailableUI = ({
    moduleSettingsList,
}: ErrorServiceUnavailableUIProps) => {
    const locale = useCurrentLocale(i18nConfig);
    const moduleSettings = moduleSettingsList.find(
        moduleSettings => moduleSettings.locale === locale
    );

    if (!moduleSettings) {
        return null;
    }

    const imagesData = getImagesData(
        moduleSettings.composable_window,
        ERROR_PAGE_IMAGES_DEVELOPMENT_KEY
    );

    const imageUrl = getImageUrl(ERROR_PAGE_IMAGE_KEY, imagesData);
    const title = getKeyValue(ERROR_PAGE_TITLE_KEY, moduleSettings);
    const description = getKeyValue(ERROR_PAGE_DESCRIPTION_KEY, moduleSettings);
    const linkHeading = getKeyValue(
        ERROR_PAGE_LIST_HEADING_KEY,
        moduleSettings
    );

    const links = moduleSettings.properties[0]?.links;

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: ['column', 'column', 'row'],
                alignItems: ['center', 'center', 'flex-start'],
                gap: [SpaceXl, SpaceXl, '80px', '100px'],
                marginTop: SpaceXxl,
                marginBottom: SpaceXxl,
                paddingLeft: [SpaceMd, SpaceXl, '80px', '100px'],
                paddingRight: [SpaceMd, SpaceXl, '80px', '100px'],
            }}
        >
            <SearchPageDigitalDataLayer
                lang={locale as LocaleType}
                pageName={ERROR_PAGE_ANALYTICS_PAGE_NAME}
                site={process.env.NEXT_PUBLIC_SITE || ''}
            />
            <Box
                sx={{
                    flexShrink: 0,
                    position: 'relative',
                    width: ['185px', '185px', '231px', '318px'],
                    height: ['185px', '185px', '231px', '318px'],
                }}
            >
                <Image
                    alt={imagesData?.body || ''}
                    src={imageUrl || ''}
                    fill
                    priority
                    style={{
                        objectFit: 'contain',
                    }}
                    unoptimized
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: ['center', 'center', 'flex-start'],
                    justifyContent: ['center', 'center', 'flex-start'],
                    textAlign: ['center', 'center', 'left'],
                }}
            >
                <Text
                    component="h2"
                    sx={{ marginBottom: [SpaceLg, SpaceLg, SpaceMd] }}
                    variant="t1"
                >
                    {title}
                </Text>
                <Text
                    component="p"
                    sx={{
                        fontSize: [
                            FontSize200,
                            FontSize200,
                            FontSize300,
                            FontSize400,
                        ],
                        marginBottom: SpaceLg,
                    }}
                    variant="t2"
                >
                    {description}
                </Text>

                <Box
                    sx={{
                        textAlign: 'left',
                    }}
                >
                    <Text
                        component="p"
                        sx={{
                            fontSize: [
                                FontSize400,
                                FontSize400,
                                FontSize400,
                                FontSize500,
                            ],
                            marginBottom: SpaceXs,
                        }}
                        variant="t2"
                    >
                        {linkHeading}
                    </Text>
                    {/* @ts-ignore - List does not export types */}
                    <List
                        style={{
                            color: ColorBlue600,
                            fontSize: FontSize200,
                            lineHeight: FontSize600,
                            margin: 0,
                            paddingLeft: SpaceLg,
                            textDecoration: 'underline',
                        }}
                    >
                        {links?.map(link => {
                            const linkText = getKeyValue(
                                link.linkTextKey,
                                moduleSettings
                            );

                            if (linkText === undefined) {
                                return null;
                            }

                            return (
                                <ListItem key={link.linkURL}>
                                    <Link href={link.linkURL}>{linkText}</Link>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Box>
        </Container>
    );
};
