'use client';

import { useSearchParams } from 'next/navigation';

import { Suspense } from 'react';

import { ToggleChildrenBySearchParamsProps } from './types';

export const ToggleChildrenBySearchParams = ({
    children,
    filterOptions,
}: ToggleChildrenBySearchParamsProps) => {
    const searchParams = useSearchParams();
    let showComponent = true;

    for (let key in filterOptions) {
        if (searchParams.get(key) === filterOptions[key]?.toString()) {
            showComponent = false;
        }
    }

    if (!showComponent) {
        return null;
    }

    return children;
};

export const ToggleChildrenBySearchParamsSuspense = ({
    children,
    filterOptions,
    fallback,
}: ToggleChildrenBySearchParamsProps) => {
    return (
        <Suspense fallback={fallback ? fallback : children}>
            <ToggleChildrenBySearchParams {...{ children, filterOptions }} />
        </Suspense>
    );
};
