import type { Config } from 'next-i18n-router/dist/types';

import {
    EN_CA_LANG_ID,
    EN_CA_LOCALE,
    EN_US_LANG_ID,
    EN_US_LOCALE,
    FR_CA_LANG_ID,
    FR_CA_LOCALE,
} from '@/constants/index';

import { languageMap } from './constants';

console.debug(
    `Setting supported languages to ${process.env.SUPPORTED_LANGUAGES} & default language to ${process.env.DEFAULT_LANGUAGE}`
);

// Zod validation should ensure type is string
const DEFAULT_LANGUAGE = process.env.DEFAULT_LANGUAGE as string;
const SUPPORTED_LANGUAGES = process.env.SUPPORTED_LANGUAGES?.split(',') || [];

export const i18nConfig: Config = {
    // cookieOptions: TODO, look into options to match WCS
    locales: SUPPORTED_LANGUAGES,
    defaultLocale: DEFAULT_LANGUAGE || '',
    noPrefix: true,
    localeDetector: (request, _config) => {
        const { searchParams } = new URL(request.url);
        const langId = searchParams.get('langId');
        if (langId) {
            return languageMap[parseInt(langId)] || DEFAULT_LANGUAGE;
        }

        const langCookie = request.headers
            .get('cookie')
            ?.match(/selectedLanguage=(-\d+);/);

        // Get locale from cookie
        if (langCookie) {
            switch (langCookie[1]) {
                case EN_US_LANG_ID:
                    return EN_US_LOCALE;
                case EN_CA_LANG_ID:
                    return EN_CA_LOCALE;
                case FR_CA_LANG_ID:
                    return FR_CA_LOCALE;
            }
        }

        return DEFAULT_LANGUAGE;
    },
};
