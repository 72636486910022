'use client';

import * as React from 'react';

import { MuiCostcoTheme } from '@costcolabs/forge-components';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/system';

import NextAppDirEmotionCacheProvider from './EmotionCache';

export default function ThemeRegistry({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <NextAppDirEmotionCacheProvider options={{ key: 'mui' }}>
            <ThemeProvider theme={{ ...MuiCostcoTheme }}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {children}
            </ThemeProvider>
        </NextAppDirEmotionCacheProvider>
    );
}
