import React from 'react';

interface ErrorBoundaryProps {
    fallback: (error: Error, errorInfo?: string | null) => React.ReactNode;
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
    errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({ error, errorInfo });
    }

    render() {
        if (this.state.hasError && this.state.error) {
            // Pass the error and error information to the fallback component
            return this.props.fallback(
                this.state.error,
                this.state.errorInfo?.componentStack
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
